import { Injectable } from '@angular/core';

import { HttpApiPersistenceService, IQueryParams } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { ICountEntity } from '../../interfaces/api/count-entity';
import {
  IStaffBankMembershipEntity,
  IStaffBankMembershipSingleActionParams,
  IStaffBankMembershipStats,
} from '../../interfaces/api/staff-bank-membership-entity';

@Injectable({
  providedIn: 'root',
})
export class StaffBankMembershipPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IStaffBankMembershipEntity
> {
  protected readonly endpoint = 'staffBankMembership';

  export(queryParams: IQueryParams) {
    return this.retrieve<ICountEntity>(queryParams, {
      controllerResource: 'exportStaffBank',
      skipSerializer: true,
    });
  }

  totalCount(queryParams: IQueryParams) {
    return this.retrieve<ICountEntity>(queryParams);
  }

  deleteStaffbank(ids: number[]) {
    const data = {
      staff_bank_memberships: ids,
    };

    return this.create<{}, IStaffBankMembershipEntity[]>(data, {
      controllerResource: 'deleteStaffbank',
      skipSerializer: true,
    });
  }

  createSingle(staffBankMembershipManualImportParams: IStaffBankMembershipSingleActionParams) {
    return this.create<{}, IStaffBankMembershipEntity>(staffBankMembershipManualImportParams, {
      controllerResource: 'createSingle',
      skipSerializer: true,
    });
  }

  staffbankStatistics() {
    return this.retrieve<IStaffBankMembershipStats>(null, {
      controllerResource: 'staffbankStatistics',
      skipSerializer: true,
    });
  }
}
